import './GalleriesPreview.scss';

import React from 'react';
import PropTypes from 'prop-types';

import GalleriesCard from './GalleriesCard';

const GalleriesPreview = ({ allGalleries, lang }) => {
	return (
		<div className={'ho-GalleriesPreview_Container'}>
			<div className={'gp-Thumbnails_Container'}>
				{allGalleries.map((gallery, i) => {
					return (
						<GalleriesCard
							key={'galleryCard-' + i}
							gallery={gallery}
							lang={lang}
						/>
					);
				})}
			</div>
		</div>
	);
};

GalleriesPreview.propTypes = {
	allGalleries: PropTypes.array.isRequired,
	lang: PropTypes.string.isRequired,
};

export default GalleriesPreview;
