import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage } from 'gatsby-plugin-image';

import config from '../../config';
import { withTranslation } from 'react-i18next';

const GalleriesCardThumb = ({ gallery, lang, t }) => {
	const gatsbyImageData =
		gallery.cover.filename.childImageSharp.gatsbyImageData;

	return (
		<div className={'gp-GalleryCardThumb_Container'}>
			<GatsbyImage
				image={gatsbyImageData}
				alt={
					t('galleryPreview.altPrefix') +
					' ' +
					gallery.translation[lang].title
				}
				title={
					t('galleryPreview.altPrefix') +
					' ' +
					gallery.translation[lang].title
				}
			/>
			<div className={'gp-GalleryCardThumb_Title'}>
				{gallery.translation[lang].title}
			</div>
		</div>
	);
};

GalleriesCardThumb.propTypes = {
	gallery: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation(config.defaultI18nextNs)(GalleriesCardThumb);
