import React from 'react';
import PropTypes from 'prop-types';

import config from '../../config';
import { withTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import GalleriesCardThumb from './GalleriesCardThumb';
import linkFactory from '../../core/linkFactory';

const GalleriesCard = (props) => {
	const { gallery, lang } = props;

	const handleClick = () => {
		const href = linkFactory.getGalleryLink(
			gallery.translation[lang].slug,
			lang
		);
		navigate(href);
	};

	return (
		<div className={'gp-GalleryCard_Container'} onClick={handleClick}>
			<GalleriesCardThumb {...props} />
		</div>
	);
};

GalleriesCard.propTypes = {
	gallery: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired,
};

export default withTranslation(config.defaultI18nextNs)(GalleriesCard);
