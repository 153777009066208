import './Home.scss';

import React from 'react';
import PropTypes from 'prop-types';

import GalleriesPreview from './GalleriesPreview';

const Home = ({ allGalleries, lang, children }) => {
	return (
		<div className={'ho-Home_Container'}>
			{children}
			<GalleriesPreview allGalleries={allGalleries} lang={lang} />
		</div>
	);
};

Home.propTypes = {
	allGalleries: PropTypes.array.isRequired,
	lang: PropTypes.string.isRequired,
	children: PropTypes.element,
};

export default Home;
